<template>
    <div>
        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                            {{ $t('message.update_class') }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                           <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{ $t('message.save_and_close') }}</el-button>
                            <el-button @click="close()">{{ $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="row" v-loading="loadingData">
            <div class="col-12 mt-2">
                <div class="modal-body">
                    <el-form ref="form" :model="form" :rules="rules" class="aticler_m" size="small">
                        <el-row :gutter="25">
                            <el-col :span="12">
                                <el-form-item :label="$t('message.class')" prop="name">
                                    <el-input v-model="form.name" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('message.price_per_day')" prop="price">
                                    <el-input v-model="form.price" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex';
    import form from '@/utils/mixins/form';
    import drawerChild from '@/utils/mixins/drawer-child';
    export default {
        mixins:[form, drawerChild],
       props: ['selected'],
        data(){
            return {
                 loadingData: false
            }
        },
        computed: {
            ...mapGetters({
                rules: 'classses/rules',
                model: 'classses/model',
                columns: 'classses/columns'
            })
        },
        methods:{
            ...mapActions({
                save: 'classses/update',
                 editModel: 'classses/show',
                empty: 'classses/empty',
            }),
            afterOpened(){
                if (this.selected && !this.loadingData) {
                    this.loadingData = true;
                    this.editModel(this.selected.id)
                        .then(async (res) => {
                            this.loadingData = false;
                            this.form = JSON.parse( JSON.stringify(this.model) );
                        })
                        .catch(err => {
                            this.loadingData = false;
                            this.$alert(err)
                        });                    
                }

            },
            afterClosed(){
              this.empty()
            },
            loadModel(){
                this.form = JSON.parse( JSON.stringify( this.model ));
            },
            submit(close = true){
                this.$refs['form'].validate((valid) => {
                    if (valid){
                        this.loadingButton = true;
                        this.save(this.form)
                            .then(res => {
                                this.loadingButton = false;
                                this.$alert(res);
                                this.parent().listChanged()
                                if (close == true) {
                                    this.close();
                                }
                            })
                            .catch(err => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            }
        }
    }

</script>
